<template>
  <div class="vm-modal">
    <iframe ref="iframe"
            id="iframeVisaMobileInfo"
            :src="getVisaMobileInfoIframeSrc()"
            title="">
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'VisaHelpModal1',
  methods: {
    getVisaMobileInfoIframeSrc () {
      return process.env.VUE_APP_WIDGET_NEW_VISA_MOBILE_URL + '/bank-info'
    }
  }
}
</script>

<style lang="scss" scoped>
.vm-modal {
  display: flex;
  justify-content: center;

  iframe#iframeVisaMobileInfo {
    width: 320px;
    height: 660px;
    border: none;
  }
}
</style>
